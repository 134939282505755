import http from "./http_public";

const DAILY_VOLUME_LIMIT = 0;
const TOP100_COINS = 100;
const MAX_COINS = 10000;

// public
const getCoin = (id, external_id) => http.get("/all_coins/" + id + '/' + external_id);
const getCoins = (type, start_item, amount, currency, top100) => {
    switch (type){
        case "All":
        case "Crypto": return getMktCapCoins(start_item, amount, currency, top100);
        case "Trending": return getTrendingCoins(start_item, amount, currency, top100);
        case "NFT": return getNFTCoins(start_item, amount, currency, top100);
        case "Gainers": return getTopGainers(start_item, amount, currency, top100);
        case "Losers": return getTopLosers(start_item, amount, currency, top100);
        case "Defi": return getDefiCoins(start_item, amount, currency, top100);
    }
}
const getCoinsCount= (type, currency, top100) => {
    switch (type){
        default:
        case "All":
        case "Trending":
        case "Crypto": return getAllCount(currency, top100);
        case "NFT": return getNFTCount(currency, top100);
        case "Defi": return getDefiCount(currency, top100);
    }
}

const getTickerCoins = () => http.get("/ticker_coins");
const getCryptoRating = (crypto_id) => http.get("/crypto_rating/" + crypto_id);
const minusCryptoRating = (crypto_id) => http.put("/crypto_rating/" + crypto_id + "/minus" );
const plusCryptoRating = (crypto_id) => http.put("/crypto_rating/" + crypto_id + "/plus" );
const getSpotMarketData = (asset, quote) => http.get("/market_data/" + asset + "/" + quote);

// Private
const getAllCount = (currency, top100) => http.get("/coins_count/" + currency + "/" + DAILY_VOLUME_LIMIT +
    "/" + (top100 ? TOP100_COINS : MAX_COINS));
const getDefiCount = (currency, top100) => http.get("/defi_coins_count/" + currency + "/" + DAILY_VOLUME_LIMIT +
    "/" + (top100 ? TOP100_COINS : MAX_COINS));
const getNFTCount = (currency, top100) => http.get("/nft_coins_count/" + currency + "/" + DAILY_VOLUME_LIMIT +
    "/" + (top100 ? TOP100_COINS : MAX_COINS));
const getTopGainers = (start_item, amount, currency, top100) => http.get("/top_gainers/" + start_item + "/" + amount +
    "/" + currency + "/" + DAILY_VOLUME_LIMIT + "/" + (top100 ? TOP100_COINS : MAX_COINS));
const getTopLosers = (start_item, amount, currency, top100) => http.get("/top_losers/" + start_item + "/" + amount +
    "/" + currency + "/" + DAILY_VOLUME_LIMIT + "/" + (top100 ? TOP100_COINS : MAX_COINS));
const getMktCapCoins = (start_item, amount, currency, top100) => http.get("/mktcap_coins/" + start_item + "/" + amount +
    "/" + currency + "/" + DAILY_VOLUME_LIMIT + "/" + (top100 ? TOP100_COINS : MAX_COINS));
const getTrendingCoins = (start_item, amount, currency, top100) => http.get("/trending_coins/" + start_item + "/" +
    amount + "/" + currency + "/" + DAILY_VOLUME_LIMIT + "/" + (top100 ? TOP100_COINS : MAX_COINS));
const getNFTCoins = (start_item, amount, currency, top100) => http.get("/nft_coins/" + start_item + "/" + amount +
    "/" + currency + "/" + DAILY_VOLUME_LIMIT + "/" + (top100 ? TOP100_COINS : MAX_COINS));
const getDefiCoins = (start_item, amount, currency, top100) => http.get("/defi_coins/" + start_item + "/" + amount +
    "/" + currency + "/" + DAILY_VOLUME_LIMIT + "/" + (top100 ? TOP100_COINS : MAX_COINS));

export default {
    getCoin,
    getCoins,
    getTickerCoins,
    getCoinsCount,
    getCryptoRating,
    minusCryptoRating,
    plusCryptoRating,
    getSpotMarketData
}