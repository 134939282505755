import kapi from "../api/kratos";

const getCurrentUserLocal = () => {
    return JSON.parse(localStorage.getItem('user'));
}

const getSession = async () => {
    try {
        const response = kapi.check_session();
        response.then(response => {
            let user = "{\"displayName\":\"" + response.data.identity.traits.name.first + " " + response.data.identity.traits.name.last + "\"}";
            localStorage.setItem("user", user);
            window.location.assign("/");
        }, function() {
            localStorage.removeItem("user");
        })
    }
    catch {
        localStorage.removeItem("user");
    }
}

const hasSession = async () => {
    try {
        const response = await kapi.check_session();
        if (response.data.active) {
            let user = "{\"email\":\"" + response.data.identity.traits.email + "\"}";
            localStorage.setItem("user", user);
        }
        else {
            localStorage.removeItem("user");
        }
    }
    catch {
        localStorage.removeItem("user");
    }
}

const isAuthenticated = () => {
    // check for token
    const user = getCurrentUserLocal();

    return !!(user && user.email);
}

const logout = () => {
    localStorage.removeItem("user");
    const response = kapi.logout_urls();
    response.then(response => {
        let url = response.data.logout_url;
        window.location.assign(url);
    })
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    logout,
    getCurrentUserLocal,
    getSession,
    hasSession,
    isAuthenticated
};
