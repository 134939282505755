
const get_consent = () => {
    return JSON.parse(localStorage.getItem('consentMode'))
}

const remove_consent = () => {
    let dl = {
        ad_storage: "denied",
        analytics_storage: "denied",
        personalization_storage: "denied",
        functionality_storage: "denied",
        security_storage: "denied",
    }
    window.gtag('consent', 'update', dl);

    localStorage.removeItem("consentMode")

    let allCookies = document.cookie.split(';');
    // set 1 Jan, 1970 expiry for all cookies
    for (let i = 0; i < allCookies.length; i++) {
        document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
    }
}

const update_consent_mode = (consent) => {
    let dl = {
        ad_storage: consent,
        analytics_storage: consent,
        personalization_storage: consent,
        functionality_storage: consent,
        security_storage: consent,
    }
    localStorage.setItem('consentMode', JSON.stringify(dl))
    window.gtag('consent', 'update', dl);
}

export default {
    get_consent,
    remove_consent,
    update_consent_mode
}