import styles from "../models/Styles";
import consentModeFunctions from "../utils/consentModeFunctions";
import {useEffect, useState} from "react";

const CookiesPopUp = (props) => {

    const [decisionMade, setDecisionMade] = useState(false)

    useEffect(() => {
        let consent = consentModeFunctions.get_consent()
        if (consent !== null) setDecisionMade(true)
    }, [])

    const updateConsent = (consent) => {
        consentModeFunctions.update_consent_mode(consent)
        setDecisionMade(true)
    }

    return (
        <div style={!decisionMade ? styles.show : styles.hide}>
            <div className="cookies-pop-up-container">
                <div className="home-contents">
                    <div className="cookies-pop-up-content-container">
                        <div className="cookies-pop-up-text">
                            Consent to analytical cookies?
                        </div>
                        <button onClick={() => {
                            updateConsent("granted")
                        }}> All Cookies
                        </button>
                        <button onClick={() => {
                            updateConsent("denied")
                        }}> Essential Only
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CookiesPopUp