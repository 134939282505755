import axios from "axios";

const header = () => {
    return {
        'Content-Type': 'application/json'
    };
}

const baseUrl = process.env.REACT_APP_API + "auth/"

let api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers:
        header()
});

api.interceptors.response.use(function (response)  {
    return response;
}, function (error) {
    return Promise.reject(error);
})


export default api;