import http from "./http_public";

const getAllSeasons = () => http.get("/seasons");
const getSeason = (season_id) => http.get("/seasons/" + season_id);
const getCategoriesForSeason = (season_id) => http.get("/categories/"+ season_id + "/true");
const getAllCategories = () => http.get("/categories");
const getCategory = (url) => http.get("/categories/"+ url);
const getModulesForCategory = (category_id) => http.get("/modules/"+ category_id + "/true");
const getRecommendedCategories = (num) => http.get("/recommended_categories/" + num)
const getLearnMenu = (amount) => http.get("/learn_links/" + amount);
const getLeaderboardPage = (time_since, limit, offset) => http.get("/leaderboard/" + time_since + "/" + limit + "/" + offset)
const getLeaderboardByLimit = (time_since, limit) => http.get("/leaderboard/" + time_since + "/" + limit)
const getNewCourse = () => http.get("/new_course_banner")
const getSectionNames = () => http.get('/section_names')
const getCategorySections = (subject, difficulty, time, price) => http.post('/category_sections', {
    'subject': subject,
    'difficulty': difficulty,
    'time': time,
    'price': price
})
const getCategorySectionsByCategory = (subject, difficulty, time, price, category) => http.post('/category_sections/' + category, {
    'subject': subject,
    'difficulty': difficulty,
    'time': time,
    'price': price
})

export default {
    getCategoriesForSeason,
    getAllCategories,
    getCategory,
    getAllSeasons,
    getSeason,
    getModulesForCategory,
    getRecommendedCategories,
    getLearnMenu,
    getLeaderboardPage,
    getLeaderboardByLimit,
    getNewCourse,
    getSectionNames,
    getCategorySections,
    getCategorySectionsByCategory
}