import http from "./http_public.js"


const getTopTrendingStory = () => http.get("/trending_news");
const getTrendingStories = (num) => http.get("/trending_news/" + num);
const getMostReadNewsStory = () => http.get("/most_read_news");
const getMostReadNewsStories = (num) => http.get("/most_read_news/" + num);
const getMostRecentNewsStory = () => http.get("/news_card");
const getMostRecentNewsStories = (num) => http.get("/news_card/" + num);

const getMixedMarketNewsStories = (offset, num) => http.get("/market_news/" + offset + "/" + num);
const getMixedMarketNewsSectionStories = (offset, num, section) => http.get("/market_news_section/" + offset + "/" + num + "/" + section);

const getNewsArticle = (link) => http.get("/news_article/" + link);

const getRelatedNews = (offset, num, type, current_id) => http.get("/related_news/" + offset + "/" + num + "/" + type + "/" + current_id);

const clickNewsArticle = (article) => http.put("/news_article_clicks/" + article)
const shareNewsArticle = (article) => http.put("/news_article_shares/" + article)

const getNewsMenu = (amount) => http.get("/news_links/" + amount);
const getRandomNewsMenu = (amount) => http.get("/random_news_links/" + amount);

export default {
    getTopTrendingStory,
    getTrendingStories,

    getMostReadNewsStory,
    getMostReadNewsStories,

    getMostRecentNewsStory,
    getMostRecentNewsStories,

    getMixedMarketNewsSectionStories,
    getMixedMarketNewsStories,

    getNewsArticle,

    getRelatedNews,

    clickNewsArticle,
    shareNewsArticle,

    getNewsMenu,
    getRandomNewsMenu
}