import React from "react";
import styles from "../models/Styles";
import PriceTicker from "../components/PriceTicker";
import {lazyRetry} from "../utils/LazyRetry";
const HomeNavBar = React.lazy(() => lazyRetry(() => import("../components/HomeNavBar")));
//Lazy loading HomeNavBar to get around layout shift issues

const HeaderContainer = (props) =>{
    const show = props.show

    return (
        <div style={show ? styles.show : styles.hide} id="navbar-aligner">
            <PriceTicker show={show} />
            <React.Suspense fallback={<div style={{width: "100vw", height: "100px"}}/>}>
                <HomeNavBar theme={props.theme} updateTheme={props.updateTheme}/>
            </React.Suspense>
        </div>
    )

}

export default HeaderContainer;