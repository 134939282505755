import axios from "axios";

const baseUrl = process.env.REACT_APP_API + "public/"

let api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

api.interceptors.response.use(function (response)  {
        return response;
    }, function (error) {
    const statusCode = error.response ? error.response.status : null;
    // go to login page if error is a 401
    if (statusCode === 401)
        localStorage.removeItem("user");
    return Promise.reject(error);
})

export default api;