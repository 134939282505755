// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function(componentImport) {
    return new Promise((resolve, reject) => {
        let refresh_name = 'retry-lazy-refreshed';
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            sessionStorage.getItem(refresh_name) || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            sessionStorage.setItem(refresh_name, 'false'); // success so reset the refresh
            resolve(component);
        }).catch(() => {
            if (!hasRefreshed) { // not been refreshed yet
                sessionStorage.setItem(refresh_name, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject('Unable to load component, this is likely due to caching');
        });
    });
};

export default {
    lazyRetry
}