const handleGenericPromiseError = (err, from = null) => {
    if (err.response){
        // Unauthorised, no origin
        if (err.response.status === 401 && !from){
            window.location.assign("/login")
        }
        // Unauthorised, has origin
        else if (err.response.status === 401 && from){
            window.location.assign("/login?from=" + from)
        }
    } else if (err.request){
        // Uncomment if needed
        //console.log(err.request);
    } else{
        // Uncomment if needed
        //console.log("Error", err.message)
    }
}

export default {
    handleGenericPromiseError
}