import http from "./http_protected";

const getUsersModuleProgress = (category) => http.get("/modules_progress/"+ category);
const getUsersChapterProgress = (module) => http.get("/chapters_progress/"+ module);
const getUsersCategoryProgress = (season) => http.get("/categories_progress/"+ season);

const getCompletedCategoryCards = (season) => http.get("/completed_categories_cards/" + season);
const getInProgressCategoryCards = (season) => http.get("/in_progress_categories_cards/" + season)

const getDashboardProgress = () => http.get("/dashboard_progress");

const getUser = () => http.get("/users");
const updateUser = (body) => http.put("/users", body);
const deleteUser = () => http.delete("/users");
const updateProfilePicture = (image, file_type) => http.post("/upload_profile_picture", {
    "ImageString": image,
    "FileFormat": file_type
})

const getTrackingCoins = () => http.get("/user_coin_tracking");
const addTrackingCoin = (coin, currency) => http.post("/user_coin_tracking", {
    "coin_id": coin,
    "currency": currency
})
const removeTrackingCoin = (coin) => http.delete("/user_coin_tracking/" + coin)

const getTrackingCoinsIDs = () => http.get("/user_coin_tracking_ids")

const getCertificate = (category) => http.get("/get_certificate/" + category)
const getProfilePicture = () => http.get("/get_profile_picture")

const getProfileDiscord = () => http.get("/user_discord")
const deleteProfileDiscord = () => http.delete("/user_discord")
const putProfileDiscord = (discord_id) => http.put("/user_discord", {
    "discord_id": discord_id
})
const postProfileDiscord = (discord_id) => http.post("/user_discord", {
    "discord_id": discord_id
})

const getCheckSubscription = () => http.get("/check_subscriptions")




export default {
    getUsersModuleProgress,
    getUsersChapterProgress,
    getUsersCategoryProgress,
    getCompletedCategoryCards,
    getInProgressCategoryCards,
    getDashboardProgress,
    getUser,
    updateUser,
    deleteUser,
    updateProfilePicture,
    getTrackingCoins,
    addTrackingCoin,
    removeTrackingCoin,
    getTrackingCoinsIDs,
    getCertificate,
    getProfilePicture,

    getProfileDiscord,
    deleteProfileDiscord,
    putProfileDiscord,
    postProfileDiscord,

    getCheckSubscription
}