import React from "react";
import cse_logo from "../assets/cse_logo_light.svg";
import twitter_logo from "../assets/twitter_logo.svg";
import instagram_logo from "../assets/instagram_logo.svg";
import youtube_logo from "../assets/youtube_logo.svg";
import styles from "../models/Styles";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import auth from "../api/auth";
import news_api from "../api/news";
import learn_api from "../api/learn_public";
import errHandler from "../utils/GenericPromiseErrorHandler";
import AffiliatePopUp from "../components/AffiliatePopUp";

const FooterContainer = (props) => {
    const show = props.show
    const banner = props.banner

    const [newsLinks, setNewsLinks] = useState([])
    const [learnLinks, setLearnLinks] = useState([])
    const [showAffiliatePopup, setShowAffiliatePopup] = useState(false);

    const handleYoutubeClick = () => {
        window.open("https://www.youtube.com/channel/UCAtp6cnfPXB7wcXg7-lT6_g")
    }

    const handleTwitterClick = () => {
        window.open("https://twitter.com/CryptoSavingExp?t=bc0i2BQb3v00levGQ9E2jA&s=09a")
    }

    const handleInstagramClick = () => {
        window.open("https://instagram.com/cryptosavingexpert?igshid=YmMyMTA2M2Y=")
    }

    const handleAffiliatePopupClick = () => {
        setShowAffiliatePopup(true);
    }

    const handleAffiliatePopupClose = () => {
        setShowAffiliatePopup(false);
    }

    const handleSubscribe = (ev) => {
        if (ev.target.id!=="open") return
        window.location.assign("/newsletter");
    }

    const GetNewsLinks = () => {
        let response = news_api.getNewsMenu(3);
        response.then (
            response => {
                let data = response.data.NewsLinks
                setNewsLinks(data);
            }
        ).catch((err) => {
            errHandler.handleGenericPromiseError(err);
        })
    }

    const GetLearnLinks = () => {
        let response = learn_api.getLearnMenu(5);
        response.then (
            response => {
                let data = response.data.LearnLinks
                setLearnLinks(data);
            }
        ).catch((err) => {
            errHandler.handleGenericPromiseError(err);
        })
    }

    const ShortenNewsTitle = (title) => {
        let words = title.split(" ");
        let new_title = "";
        let idx = 0;
        while (new_title.length < 30 && idx !== words.length) {
            new_title += " " + words[idx];
            idx += 1;
        }
        if (new_title !== title) new_title += "..."
        return new_title
    }

    useEffect(() => {
        GetNewsLinks()
        GetLearnLinks()
    }, [])

    return (
        <>
        <div style={show ? styles.show : styles.hide}>
            <div className="home-footer-container" id="home-footer-container">
                <div className="footer-subscribe-container" style={banner ? styles.show : styles.hide}>
                    <div className="footer-subscribe-title">Don't miss out on crypto news</div>
                    <div className="footer-subscribe-text">For all the latest guides, news and everything crypto, sign up to our newsletter</div>
                    <div className="footer-subscribe-button-container">
                        <button className="home-section-news-button" id="open" onClick={handleSubscribe}>Subscribe</button>
                    </div>
                </div>
                <div className="footer-container">
                    <div className="home-contents">
                        <div className="row">
                            <div className="footer-logo-container col-sm-2">
                                <img className="footer-cse-logo" src={cse_logo} alt="CSE logo"/>
                            </div>
                            <div className="footer-links-container col-sm-10">
                                <div className="col">
                                    <Link to={"/news"}>
                                        <div className="footer-topic-heading">NEWS</div>
                                    </Link>
                                    {newsLinks && newsLinks.map((newsLink) => {
                                        return (
                                            <Link to={"/story/" + newsLink.article_link} key={newsLink.article_link}>
                                                <div className="row">
                                                    <div className="footer-topic-text" title={newsLink.title}>
                                                        {ShortenNewsTitle(newsLink.title)}
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </div>
                                <div className="col">
                                    <Link to={"/guides"}>
                                        <div className="footer-topic-heading">GUIDES</div>
                                    </Link>
                                    <Link to={"/guides?topic=Exchanges"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Exchanges</div>
                                        </div>
                                    </Link>
                                    <Link to={"/guides?topic=Wallets"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Wallets</div>
                                        </div>
                                    </Link>
                                    <Link to={"/guides?topic=Tax"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Tax</div>
                                        </div>
                                    </Link>
                                    <Link to={"/guides?topic=Crypto%20Cards"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Crypto Cards</div>
                                        </div>
                                    </Link>
                                    <Link to={"/guides?topic=Stay%20Safe"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Stay Safe</div>
                                        </div>
                                    </Link>
                                    <Link to={"/guides?topic=Networks"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Networks</div>
                                        </div>
                                    </Link>
                                    <Link to={"/guides?topic=Metaverse"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Metaverse</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col">
                                    <Link to={"/courses"}>
                                        <div className="footer-topic-heading">ACADEMY</div>
                                    </Link>
                                    {learnLinks && learnLinks.map((learnLink) => {
                                        return (
                                            <Link to={"/category/" + learnLink.category_link}>
                                                <div className="row">
                                                    <div className="footer-topic-text">{learnLink.name}</div>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </div>
                                <div className="col">
                                    <Link to={"/markets"}>
                                        <div className="footer-topic-heading">MARKETS</div>
                                    </Link>
                                    <Link to={"/markets?tab=trending"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Trending</div>
                                        </div>
                                    </Link>
                                    <Link to={"/markets?tab=gainers"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Gainers</div>
                                        </div>
                                    </Link>
                                    <Link to={"/markets?tab=losers"}>
                                        <div className="row">
                                            <div className="footer-topic-text">Losers</div>
                                        </div>
                                    </Link>
                                    {auth.isAuthenticated() ?
                                        <Link to={"/markets?tab=watchlist"}>
                                            <div className="row">
                                                <div className="footer-topic-text">Watchlist</div>
                                            </div>
                                        </Link>
                                        :
                                        null
                                    }
                                </div>
                                <div className="col">
                                    <Link to={"/a-z"}>
                                        <div className="footer-topic-heading">A - Z</div>
                                    </Link>
                                </div>
                                <div className="col follow-us">
                                    <div className="footer-topic-heading">FOLLOW US</div>
                                    <div className="social-media-logo">
                                        <div className="social-media-logo-button">
                                            <img src={twitter_logo} className="link" onClick={handleTwitterClick}
                                                 alt="Twitter logo"/>
                                        </div>
                                        <div className="social-media-logo-button">
                                            <img src={instagram_logo} className="link" onClick={handleInstagramClick}
                                                 alt="Instagram logo"/>
                                        </div>
                                        <div className="social-media-logo-button">
                                            <img src={youtube_logo} className="link" onClick={handleYoutubeClick}
                                                 alt="Youtube logo"/>
                                        </div>
                                    </div>
                                    <div className="affiliate-link-container">
                                        <div className="affiliate-link link" onClick={handleAffiliatePopupClick}><u>Links may help fund this site</u></div>
                                        <AffiliatePopUp show={showAffiliatePopup} close={handleAffiliatePopupClose.bind(this)}/>
                                    </div>
                                </div>
                            </div>


                            <div className="legal-container">
                                <div className="legal-content">
                                    <Link to={"/terms-of-use"}>
                                        <div className="legal-text link">TERMS OF USE</div>
                                    </Link>
                                    <Link to={"/plus-terms-and-conditions"}>
                                        <div className="legal-text link">CSE PLUS+ T&C</div>
                                    </Link>
                                    <Link to={"/privacy-statement"}>
                                        <div className="legal-text link">PRIVACY</div>
                                    </Link>
                                    <Link to={"/community-rules"}>
                                        <div className="legal-text link">COMMUNITY</div>
                                    </Link>
                                    <Link to={"/disclaimers"}>
                                        <div className="legal-text link">DISCLAIMERS</div>
                                    </Link>
                                    <Link to={"/how-we-are-funded"}>
                                        <div className="legal-text link">FUNDING</div>
                                    </Link>
                                    <Link to={"/about-us"}>
                                        <div className="legal-text link">ABOUT US</div>
                                    </Link>
                                    <Link to={"/advertise"}>
                                        <div className="legal-text link">ADVERTISE</div>
                                    </Link>
                                    <Link to={"/cookies"}>
                                        <div className="legal-text link">COOKIES</div>
                                    </Link>
                                    <Link to={"/competition"}>
                                        <div className="legal-text link">COMPETITION</div>
                                    </Link>
                                    <Link to={"/affiliate-terms"}>
                                        <div className="legal-text link">AFFILIATE TERMS</div>
                                    </Link>
                                </div>
                            </div>
                            <div className="legal-container">
                                <div className="legal-content">
                                    <div className="legal-text">© 2024 cryptosavingexpert.com. All rights reserved.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FooterContainer;