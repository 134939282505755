import React, {useEffect, useState} from "react";
import HeaderContainer from "./HeaderContainer";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import FooterContainer from "./FooterContainer";
import Themes from "../models/Themes";
import {lazyRetry} from "../utils/LazyRetry";
import NotFoundPage from "../pages/NotFoundPage";
import CookiesPopUp from "../components/CookiesPopUp";

const CryptoNews = React.lazy(() => lazyRetry(() => import("../pages/CryptoNews")));
const Home = React.lazy(() => lazyRetry(() => import("../pages/Home")));
const CryptoInfo = React.lazy(() => lazyRetry(() => import("../pages/CryptoInfo")));
const CryptoLearn = React.lazy(() => lazyRetry(() => import("../pages/CryptoLearn")));
const CryptoCoins = React.lazy(() => lazyRetry(() => import("../pages/CryptoCoins")));
const ChapterPage = React.lazy( () => lazyRetry(() => import("../pages/ChapterPage")));
const CoinPortfolio = React.lazy( () => lazyRetry(() => import("../pages/PortfolioPage")))
const CoinDetail = React.lazy( () => lazyRetry(() => import("../pages/CoinDetail")));
const LearnCategory = React.lazy( () => lazyRetry(() => import("../pages/LearnCategory")));
const InfoArticle = React.lazy( () => lazyRetry(() => import("../pages/InfoArticle")));
const EncyclopediaPage = React.lazy( () => lazyRetry(() => import("../pages/EncyclopediaPage")));
const Encyclopedia = React.lazy( () => lazyRetry(() => import("../pages/Encyclopedia"))); //new
const NewsStory = React.lazy( () => lazyRetry(() => import("../pages/NewsStory")));
const Login = React.lazy( () => lazyRetry(() => import("../pages/Login")));
const Register = React.lazy( () => lazyRetry(() => import("../pages/Register")));
const Recovery = React.lazy( () => lazyRetry(() => import("../pages/Recovery")));
const Settings = React.lazy( () => lazyRetry(() => import("../pages/Settings")));
const Error = React.lazy( () => lazyRetry(() => import("../pages/Error")));
const Verification = React.lazy( () => lazyRetry(() => import("../pages/Verification")));
const QuizPage = React.lazy( () => lazyRetry(() => import("../pages/QuizPage")));
const SearchPage = React.lazy( () => lazyRetry(() => import("../pages/SearchPage")));
const Profile = React.lazy( () => lazyRetry(() => import("../pages/Profile")));
const RegistrationComplete = React.lazy( () => lazyRetry(() => import("../pages/RegistrationComplete")));
const TermsOfUse = React.lazy(() => lazyRetry(() => import("../pages/TermsOfUse")));
const CommunityRules = React.lazy(() => lazyRetry(() => import("../pages/CommunityRules")));
const PrivacyPolicy = React.lazy(() => lazyRetry(() => import("../pages/PrivacyPolicy")));
const Disclaimers = React.lazy(() => lazyRetry(() => import("../pages/Disclaimers")));
const AboutUs = React.lazy(() => lazyRetry(() => import("../pages/AboutUs")));
const Advertise = React.lazy(() => lazyRetry(() => import("../pages/Advertise")));
const HowWeAreFunded = React.lazy(() => lazyRetry(() => import("../pages/HowWeAreFunded")));
const Newsletter = React.lazy(() => lazyRetry(() => import("../pages/Newsletter")));
const Cookies = React.lazy(() => lazyRetry(() => import("../pages/Cookies")));
const Competition = React.lazy(() => lazyRetry(() => import("../pages/Competition")));
const PortfolioTransactions = React.lazy(() => lazyRetry(() => import("../pages/PortfolioTransactions")));
const PortfolioSinglePage = React.lazy(() => lazyRetry(() => import("../pages/PortfolioSinglePage")));
const CSEPlus = React.lazy(() => lazyRetry(() => import("../pages/CSEPlus")));
const CSEplusTNC = React.lazy(() => lazyRetry(() => import("../pages/CSEplusTermsAndConditions")));
const Deals = React.lazy(() => lazyRetry(() => import("../pages/Deals")));
const StripeSuccess = React.lazy(() => lazyRetry(() => import("../pages/StripeSuccess")));
const Leaderboard = React.lazy(() => lazyRetry(() => import("../pages/LeaderboardPage")));
const AffiliateTerms = React.lazy(() => lazyRetry(() => import("../pages/AffiliateTerms")));
const suspense_fallback = <div style={{width: "100vw", height: "100vh"}}/>

const AppLayout = () => {

    const [headerComponent, setHeaderComponent] = useState(false)
    const [footerComponent, setFooterComponent] = useState(false)
    const [showFooterBanner, setShowFooterBanner] = useState(true)

    const location = useLocation();
    const [url, setURL] = useState("")

    const [theme, setTheme] = useState(0);

    const no_headers = [
        "quiz",
    ]

    const no_footers = [
        "quiz",
    ]

    const no_banner = [
        "newsletter",
        "portfolio"
    ]

    const HeaderLayout = () => {
        const { pathname } = useLocation();
        let updated = false;

        no_headers.forEach((header) => {
            if (pathname.includes(header)) {
                setHeaderComponent(false)
                updated = true
            }
        })
        if (updated === false) { setHeaderComponent(true) }

        return ( <></> )
    };

    const FooterLayout = () => {
        const { pathname } = useLocation();
        let updated = false;
        let banner_updated = false;

        no_footers.forEach((footer) => {
            if (pathname.includes(footer)) {
                setFooterComponent(false)
                updated = true
            }
        })

        no_banner.forEach((banner) => {
            if (pathname.includes(banner)) {
                setShowFooterBanner(false)
                banner_updated = true
            }
        })
        if (updated === false) { setFooterComponent(true); }
        if (banner_updated === false) {setShowFooterBanner(true); }

        return ( <></> )
    };

    const OtherHeaderLayout = () => {
        setHeaderComponent(false)
        return ( <></> )
    }

    const OtherFooterLayout = () => {
        setFooterComponent(false)
        setShowFooterBanner(false);
        return ( <></> )
    }

    const UpdateTheme = (theme) => {
        localStorage.setItem("theme", theme)
        setTheme(theme);
    }

    useEffect(() => {
        let current_theme = theme;
        if (!current_theme) {
            let th = localStorage.getItem("theme")
            if (th) {
                setTheme(parseInt(th));
                current_theme = parseInt(th);
            }
            else {
                setTheme(Themes.THEME_LIGHT);
                current_theme = Themes.THEME_LIGHT;
            }
        }
        let head = document.head;
        let link = document.createElement("link");

        link.type = "text/css";
        link.rel = "stylesheet";

        if (current_theme === Themes.THEME_LIGHT)
            link.href = window.location.origin + "/theme_light.css";
        else
            link.href = window.location.origin + "/theme_dark.css";

        head.appendChild(link);

        return () => { head.removeChild(link); }

    }, [theme]);

    useEffect(() =>  {
        setURL(window.location.href)
    },[location.pathname])

    return (
        <>
            <Helmet>
                <link rel="canonical" href={url} />
            </Helmet>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
                  integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
                  crossOrigin="anonymous"/>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
                    crossOrigin="anonymous" />
            <meta name="viewport" content="width=device-width, initial-scale=1"/>

            <HeaderContainer show={headerComponent} theme={theme} updateTheme={UpdateTheme.bind(this)}/>

            <>
                <Routes>
                    <Route exact path="/home"
                           element={<Navigate to="/"/>}/>
                    <Route path="/" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Home theme={theme}/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/profile" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Profile updateTheme={UpdateTheme.bind(this)} theme={theme} />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/login" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Login/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/verification" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Verification />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/register" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Register />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/recovery" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Recovery />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/settings" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Settings />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/error" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Error />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/news" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <CryptoNews />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/guides" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <CryptoInfo/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/courses" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <CryptoLearn theme={theme} />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/markets" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <CryptoCoins theme={theme}/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/story">
                        <Route path=":storyLink" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <NewsStory />
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/article">
                        <Route path=":articleLink" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <InfoArticle theme={theme}/>
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/word">
                        <Route path=":id" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <EncyclopediaPage />
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route exact path="/encyclopedia"
                           element={<Navigate to="/a-z"/>}/>
                    <Route path="/a-z" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Encyclopedia />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/category">
                        <Route path=":categoryLink" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <LearnCategory theme={theme}/>
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/chapter">
                        <Route path=":moduleLink" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <ChapterPage theme={theme}/>
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/portfolio" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <CoinPortfolio theme={theme}/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/coin">
                        <Route path=":id" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <CoinDetail theme={theme}/>
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/quiz">
                        <Route path=":id" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <QuizPage />
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/search" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <SearchPage/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/registration_complete" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <RegistrationComplete/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/terms-of-use" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <TermsOfUse/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/community-rules" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <CommunityRules/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/privacy-statement" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <PrivacyPolicy/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/disclaimers" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Disclaimers/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/about-us" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <AboutUs/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/advertise" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Advertise/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/how-we-are-funded" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <HowWeAreFunded/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/newsletter" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Newsletter/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/cookies" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Cookies />
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/competition" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Competition/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/portfolio-transactions">
                        <Route path=":portfolioId" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <PortfolioTransactions/>
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/portfolio">
                        <Route path=":portfolioId" element={
                            <React.Suspense fallback={suspense_fallback}>
                                <HeaderLayout />
                                <PortfolioSinglePage theme={theme}/>
                                <FooterLayout />
                            </React.Suspense>
                        }/>
                    </Route>

                    <Route path="/cse-plus" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <CSEPlus theme={theme}/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>

                    <Route path="/plus-terms-and-conditions" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <CSEplusTNC theme={theme}/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>

                    <Route path="/deals" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Deals theme={theme} />
                            <FooterLayout />
                        </React.Suspense>
                    }/>

                    <Route path="/success" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <StripeSuccess theme={theme} />
                            <FooterLayout />
                        </React.Suspense>
                    }/>

                    <Route path="/leaderboard" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <Leaderboard/>
                            <FooterLayout />
                        </React.Suspense>
                    }/>
                    <Route path="/affiliate-terms" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <HeaderLayout />
                            <AffiliateTerms />
                            <FooterLayout />
                        </React.Suspense>
                    }/>

                    <Route path="*" element={
                        <React.Suspense fallback={suspense_fallback}>
                            <OtherHeaderLayout />
                            <NotFoundPage />
                            <OtherFooterLayout />
                        </React.Suspense>
                    }/>
                </Routes>
            </>

            <FooterContainer show={footerComponent} banner={showFooterBanner}/>
            <CookiesPopUp />

        </>
    )
}

export default AppLayout;