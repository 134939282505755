import {useIntercom} from "react-use-intercom";
import React, {useEffect, useState} from "react";
import users_api from "../api/users";
import auth from "../api/auth";
import AppLayout from "./AppLayout";
import {BrowserRouter as Router} from "react-router-dom";


const IntercomContainer = () => {
    const {boot, hardShutdown, update} = useIntercom()

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        if (!isAuthenticated) {
            if (localStorage.getItem("CSEAllowCookies") === "1") {
                hardShutdown()
                boot({
                    customAttributes: {
                        logged_in: false,
                        plus_user: false,
                        premium_user: false,
                    }
                });
            }
            return;
        }
        GetUser()
        CheckSubscription()
    },[isAuthenticated])

    const GetUser = () => {
        let response = users_api.getUser();
        response.then(
            response => {
                setUser(response.data.Users[0])
            }
        ).catch(() => {})
    }

    const CheckSubscription = () => {
        if (!auth.isAuthenticated()) return;
        let response = users_api.getCheckSubscription();
        response.then (
            response => {
                let response_data = response.data.Subscription;
                if (localStorage.getItem("CSEAllowCookies") === "1") {
                    update({
                        name: user && user.FirstName,
                        email: user && user.Email,
                        customAttributes: {
                            logged_in: true,
                            plus_user: response_data.plus === true,
                            premium_user: response_data.premium === true,
                            created_at: user && user.RegisteredDate,
                        }
                    })
                }
            }
        ).catch(() => {})
    }

    useEffect(() => {
        auth.hasSession().then(() => setIsAuthenticated(auth.isAuthenticated()));
    }, [])

    return (
        <Router>
            <AppLayout />
        </Router>
    )
}

export default IntercomContainer