import Styles from "../models/Styles";

const AffiliatePopUp = (props) => {
    const show = props.show;
    const close = props.close;

    const handleNavClick = () => {
        window.location.assign("/how-we-are-funded");
        close();
    }

    return (
        <>
            <div className="affiliate-popup-container" style={show ? Styles.show : Styles.hide}>
                <div className="affiliate-close link" onClick={close}>
                    <u>Close</u>
                </div>
                <div className="affiliate-popup-text">
                    If you click on a link to a third party provider, we may get paid.
                    This is what funds this website and the work that goes into it, and keeps it free to use.
                    We would like to reassure you, however, that:
                </div>
                <ul className="affiliate-popup-text">
                    <li>
                        This never impacts our editorial recommendations or features;
                    </li>
                    <li>
                        You will always get as good a deal (or better) compared to if you went to the provider direct.
                    </li>
                </ul>
                <div className="affiliate-popup-text">
                    For a more detailed explanation, please visit
                    <div className="affiliate-nav link" onClick={handleNavClick}>
                        <u>How We Are Funded.</u>
                    </div>
                </div>
                <div className="affiliate-triangle-outer"></div>
                <div className="affiliate-triangle"></div>
            </div>
        </>
    )
}

export default AffiliatePopUp