import background from "../assets/blue_bkgd.svg"
import stars from "../assets/stars.svg"
import crashed_rocket from "../assets/crashed_rocket.svg"
import planet from "../assets/rock_planet.svg"

import logo from "../assets/cse_logo_light.svg"
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const NotFoundPage = () => {
    let navigate = useNavigate()

    const ButtonClick = (ev) => {
        if (ev.target.id !== "click") return
        navigate("/")
    }

    useEffect(() => {
        document.title = 'CSE 404 ERROR';
    }, [])

    return (
        <div className="page-not-found-container">
            <img src={background} className="blue-background" alt="Space Background"/>
            <img src={stars} className="stars-background" alt="Space stars"/>
            <img src={planet} className="rock-planet" alt="Planet Background" />
            <img src={crashed_rocket} className="crashed-rocket" alt="Crashed rocket" />
            <div className="page-not-found-text-container">
                <div className="page-not-found-image-container">
                    <img src={logo} alt="cse logo"/>
                </div>
                <div className="page-not-found-title-container">
                    404 ERROR - PAGE NOT FOUND
                </div>
                <div className="page-not-found-message-container">
                    <div className="orange"> HOUSTON </div>
                    <div> WE HAVE A </div>
                    <div className="orange"> PROBLEM </div>
                </div>
                <div className="page-not-found-description-container">
                    The page you requested could not be found
                </div>
                <div className="page-not-found-home-button-container">
                    <div className="page-not-found-home-button link" onClick={ButtonClick} id="click">
                        Home
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage