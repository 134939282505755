import coins_api from "../api/coins";
import up_arrow from "../assets/price_up.svg"
import down_arrow from "../assets/price_down.svg"
import {useEffect, useState} from "react";
import styles from "../models/Styles";
import {Link} from "react-router-dom";
import errHandler from "../utils/GenericPromiseErrorHandler";


const PriceTicker = (props) => {
    const [coins, setCoins] = useState([]);

    let socket = undefined;

    const show = props.show

    const updateCoins = () => {
        if (!show || show === false) return;
        let response = coins_api.getTickerCoins();
        response.then(
            response => {
                let data = response.data.Ticker;
                setCoins(data);
            }
        ).catch((err) => {
            errHandler.handleGenericPromiseError(err);
        })
    }

    useEffect(() => {
        if (!show || show === false) {
            if (!socket) return;
            socket.close()
        }
        if (!show || show === false) return;
        updateCoins();
        let interval = setInterval(() => {updateCoins()}, 60000);
        return (() => {
            clearInterval(interval);
        })
    }, [show]);

    return (
        <>
            <div className="ticker-container" id="ticker-container">
                <div className="home-contents">
                    <div className="row" id="ticker">
                        {coins && coins.map((coin, idx) => {
                            return(
                                    <div className={idx>0 ? "ticker-seperator ticker-section-container" : "ticker-section-container"}>
                                        <Link to={"coin/" + coin.Name + "?external_id=" + coin.Id + "&section=All"}>
                                            <div className="ticker-section-coin">
                                                <div className="ticker-row-label">
                                                    <div className="col">
                                                        <div className="ticker-section-top-gainer" style={idx === 2 ? styles.show : styles.hide}>Top Gainer</div>
                                                        <div className="ticker-section-top-loser" style={idx === 3 ? styles.show : styles.hide}>Top Loser</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="ticker-24hr-label">24hr</div>
                                                    </div>
                                                </div>
                                                <div className="ticker-row-info">
                                                    <div className="ticker-coin-image">
                                                        <img className="ticker-coin-image" src={coin.Logo} alt="Coin logo"/>
                                                    </div>
                                                    <div className="ticker-coin-name">{coin.Name}</div>
                                                    <div className="ticker-coin-price">${coin.Price.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}</div>
                                                    <div className="ticker-coin-change-container">
                                                        <div className="ticker-change-arrow">
                                                            <img id={coin.Name+"_arrow"} src={coin.Change>0.0 ? up_arrow : down_arrow}
                                                                 alt="Arrow logo" />
                                                        </div>
                                                        <div className={coin.Change>0.0 ?
                                                            "ticker-positive ticker-coin-change" : "ticker-negative ticker-coin-change"}
                                                             id={coin.Abbreviation+"_change"}>{coin.Change.toFixed(1)}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PriceTicker