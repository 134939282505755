import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import './general_fonts.css';
import './media_max1200.css';
import './media_max992.css';
import './media_max768.css';
import './media_max575.css';
import './media_max480.css';
import './media_max383.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HelmetProvider} from "react-helmet-async";

Sentry.init({
    dsn: "https://c760189091294ab3b0b11aff086c7159@sentry.cryptosavingexpert.com/3",
    integrations: [new Integrations.BrowserTracing()],

    // Update Accordingly
    tracesSampleRate: 1.0,
});

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
  <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
} else {
  ReactDOM.render(
  <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
