import React, {useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import './utils/Loader';
import AppLayout from "./containers/AppLayout";
import IntercomContainer from "./containers/IntercomContainer";

import { IntercomProvider } from "react-use-intercom";

function App() {
    const APP_ID = "wxl2v4tv"

    useEffect(() => {
        let addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                autoDisplay: false
            },
            "google_translate_element"
        );
    };

    return (
        <IntercomProvider appId={APP_ID}>
            <IntercomContainer/>
        </IntercomProvider>
    )
}

export default App;
